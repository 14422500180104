import React from 'react'

const AboutUs = () => {
    return (
        <section className="about-layout1 pt-130 pb-130" id="about-us">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-7">
                        <div className="heading__layout2 mb-60">
                            {/* <h2 className="heading__subtitle">
                                Leading The Way In Building And Civil Construction
                            </h2> */}
                            <h3 className="heading__title">
                                India is ready for Solar Energy, All we need
                                to implement it well!
                            </h3>
                        </div>
                        {/* /.heading__layout2 */}
                    </div>
                    {/* /.col-lg-7 */}
                </div>
                {/* /.row */}
                <div className="row align-items-center">
                    <div className="col-sm-12 col-md-12 col-lg-6">
                        <div className="video-banner-layout2">
                            <img
                                src="/assets/images/about/1.jpg"
                                alt="about"
                                className="w-100"
                            />
                            <div className="video-has-img">
                                <img src="/assets/images/video/1.jpg" alt="video" />
                                <a
                                    className="video__btn video__btn-white popup-video"
                                    href="https://www.youtube.com/embed/dDAP-4Ve2ZI"
                                >
                                    <div className="video__player">
                                        <i className="fa fa-play" />
                                    </div>
                                </a>
                                <span className="video__btn-title">Watch Our Intro!</span>
                            </div>
                        </div>
                        {/* /.video-banner */}
                    </div>
                    {/* /.col-lg-5 */}
                    <div className="col-sm-12 col-md-12 col-lg-6">
                        <div className="about__text">
                            <div className="text__icon">
                                <i className="icon-green-energy3" />
                            </div>
                            <p className="heading__desc font-weight-bold color-secondary mb-30">
                                <b className='color-primary'>Welcome to Suriearn</b> Smart Solar Systems Private
                                Limited, your leading provider of rooftop solar panel
                                solutions.
                            </p>
                            <p className="heading__desc mb-20">We are committed to enabling both homeowners and businesses to tap into solar power, thereby reducing energy expenses to great degree.</p>
                            <p className="heading__desc mb-20">
                                At Suriearn Smart Solar Systems Private Limited, we
                                recognize the critical need for sustainable practices and
                                the swift shift towards renewable energy sources. Our
                                state-of-the-art rooftop solar solutions are designed to
                                make the adaptation of solar energy simple, economical, sustainable renewable energy practice and convenient for all.
                            </p>
                            <div className="d-md-flex align-items-center mt-30">
                                <img src="/assets/images/about/singnture.png" className='d-block mt-3 mt-md-0' alt="singnture" />
                            </div>
                        </div>
                        {/* /.about__text */}
                    </div>
                    {/* /.col-lg-5 */}
                </div>
                {/* /.row */}
            </div>
            {/* /.container */}
        </section>
    )
}

export default AboutUs